@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import 'primereact/resources/themes/nova/theme.css';
@import 'primereact/resources/primereact.min.css';
@import 'primeicons/primeicons.css';


/* Tailwind styles */
@layer base {
	body, h1, h2, h3, p {
	  @apply m-0 p-0;
	}
  
	body {
	  @apply font-sans;
	}
  
	a {
	  @apply text-blue-400 no-underline;
	}
  
	a:hover {
	  @apply text-blue-500;
	}
}


/* PrimeReact styles */

/* DataTable row border */
.p-datatable .p-datatable-tbody > tr {
	border: 1px solid #d1d5db; /* This is Tailwind's gray-300 */
}

/* DataTable center content in each cell */
.p-datatable .p-datatable-tbody > tr > td {
	text-align: center !important;
}

/* DataTable header border*/
.p-datatable-thead > tr {
	border: 1px solid #d1d5db; /* This is Tailwind's gray-300 */
}

/* DataTable bottom paginator border */
.p-paginator-bottom{
	border-bottom: 1px solid #d1d5db; /* This is Tailwind's gray-300 */
	border-left: 1px solid #d1d5db; /* This is Tailwind's gray-300 */
	border-right: 1px solid #d1d5db; /* This is Tailwind's gray-300 */	
}

/* DataTable Hover effect for rows */
.p-datatable .p-datatable-tbody > tr:hover {
	background-color: #fafafa !important;
}
.responsive-table {
	width: 100%;
  }
  
.p-tabmenu a {
	color: black;
  }

  .p-datatable .p-datatable-tbody > tr.bg-primary-xchem {
	background-color: #CFDFF1!important;
	
  }

  .p-datatable .p-datatable-tbody > tr.bg-primary-hitgen {
	background-color: #c3d0ac!important;
  } 

  .p-datatable .p-datatable-tbody > tr.bg-primary-external {
	background-color: #B9B7BD!important;
  } 

  .tab-background-1 {
	background-color: #f0f0f0; /* Example background color */
	color: #cb2424; /* Example text color */
  }


  .p-datepicker{
	background: #afb0b9;
	
	
  }
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
	margin-right: 20px;

  }

  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
	background: linear-gradient(to right, #f36548, #e53201);
  }

  /* .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link{
	background: #f36548;
  } */

  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
	background: linear-gradient(to right, #f36548, #e53201);
  }


  .custom-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight) .p-menuitem-link {
	color: black; /* Text color for unselected tabs */
  }
  
  .custom-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
	color: white; /* Text color for selected tab */
  }


  /* from-[#e53201] to-[#f36548] */