/* styles.css */

h1, h2, h3 {
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px;
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 18px;
}

strong {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  width: 100%;
}

tr {
  border-top: 1px solid #c6cbd1;
  background: #fff;
}

th, td {
  padding: 6px 13px;
  border: 1px solid #dfe2e5;
}
